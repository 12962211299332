import React from "react"
import PageTemplate from "../components/pageTemplate"

let Rezeptbestellung = () => (
  <PageTemplate title="Rezeptbestellung">
    <h1>Vorbestellungen von Rezepten, Überweisungs,- Verordnungsscheinen</h1>
    <p>
      Als erweitertes Service vor allem für unsere Berufstätigen und Patienten
      mit Mobilitätseinschränkungen bieten wir auch nach Ablauf der
      Coronamassnahmen weiterhin die Vorbestellungen von Rezepten,
      Überweisungs,- Verordnungsscheinen an.
    </p>
    <p>
      <strong>Per Email:</strong>
    </p>
    <p>
      <a href={"mailto:anmeldung@dr-joerg.at"}>anmeldung@dr-joerg.at</a>
    </p>
    <p>
      <strong>Telefonisch</strong>
    </p>
    <p>
      03119/21000 zwischen 10:30 und 11:30 Uhr Abholung bzw. Übermittlung nach
      individueller Vereinbarung
    </p>
  </PageTemplate>
)

export default Rezeptbestellung
